import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  add(event) {
    const select = event.target
    const selectedValue = select.value
    
    if (!selectedValue) return
    
    const template = select.querySelector(`option[value="${selectedValue}"]`)
    const templateName = template.textContent
    
    // Create new template entry
    const div = document.createElement('div')
    div.className = 'flex items-center gap-2 bg-base-200 p-2 rounded'
    div.innerHTML = `
      <span class="flex-grow">${templateName}</span>
      <input type="hidden" name="config[template_ids][]" value="${selectedValue}">
      <button class="btn btn-ghost btn-sm" type="button" data-action="template-selector#moveUp">↑</button>
      <button class="btn btn-ghost btn-sm" type="button" data-action="template-selector#moveDown">↓</button>
      <button class="btn btn-ghost btn-sm" type="button" data-action="template-selector#remove">×</button>
    `
    
    // Insert before the select element
    select.parentElement.insertBefore(div, select)
    
    // Remove the option from select
    template.remove()
    
    // Reset select
    select.value = ""
    
    this.updateMoveButtons()
  }
  
  remove(event) {
    const templateDiv = event.target.closest('.flex')
    const hiddenInput = templateDiv.querySelector('input[type="hidden"]')
    const templateId = hiddenInput.value
    const templateName = templateDiv.querySelector('.flex-grow').textContent
    
    // Add back to select
    const select = this.element.querySelector('select')
    const option = document.createElement('option')
    option.value = templateId
    option.textContent = templateName
    select.appendChild(option)
    
    // Remove the template div
    templateDiv.remove()
    
    this.updateMoveButtons()
  }
  
  moveUp(event) {
    const templateDiv = event.target.closest('.flex')
    const previousSibling = templateDiv.previousElementSibling
    if (previousSibling && previousSibling.classList.contains('flex')) {
      previousSibling.before(templateDiv)
      this.updateMoveButtons()
    }
  }
  
  moveDown(event) {
    const templateDiv = event.target.closest('.flex')
    const nextSibling = templateDiv.nextElementSibling
    if (nextSibling && nextSibling.tagName !== 'SELECT') {
      nextSibling.after(templateDiv)
      this.updateMoveButtons()
    }
  }
  
  updateMoveButtons() {
    const templateDivs = Array.from(this.element.querySelectorAll('.flex'))
    
    templateDivs.forEach((div, index) => {
      const upButton = div.querySelector('[data-action="template-selector#moveUp"]')
      const downButton = div.querySelector('[data-action="template-selector#moveDown"]')
      
      if (upButton) upButton.disabled = index === 0
      if (downButton) downButton.disabled = index === templateDivs.length - 1
    })
  }
}