import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template"]

  add(event) {
    event.preventDefault()
    
    // Get the template element's HTML directly
    const template = this.templateTarget
    const newContent = template.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    
    // Find the entries container and append the new content
    const entriesContainer = document.querySelector('#entries')
    const addButton = event.currentTarget
    addButton.insertAdjacentHTML('beforebegin', newContent)
  }

  remove(event) {
    event.preventDefault()
    const wrapper = event.currentTarget.closest('.entry')
    wrapper.remove()
  }
}
